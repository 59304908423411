import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'app-mm-autocomplete',
  templateUrl: './mm-autocomplete.component.html',
  styleUrls: ['./mm-autocomplete.component.scss'],
})
export class MmAutocompleteComponent implements OnInit {
  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;
  @Input() styleClass: string;
  @Input() items: any[] = [];
  @Input() value: any = null;

  @Output() search = new EventEmitter();
  @Output() selectItem = new EventEmitter();
  blurTimeout = null;

  constructor(private ngZone: NgZone) {
  }

  ngOnInit() {
  }

  blurInput() {
    this.blurTimeout = setTimeout(() => {
      this.ngZone.run(() => {
        this.items = [];
        const oldValue = this.value;
        this.value = null;
        setTimeout(() => {
          this.value = oldValue;
        }, 0);
      });
    }, 500);
  }

  selectItemHandler(item) {
    clearTimeout(this.blurTimeout);
    this.selectItem.emit(item);
    this.items = [];
  }

}
